exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ahamed-zawab-js": () => import("./../../../src/pages/ahamed-zawab.js" /* webpackChunkName: "component---src-pages-ahamed-zawab-js" */),
  "component---src-pages-akshata-manelkar-js": () => import("./../../../src/pages/akshata-manelkar.js" /* webpackChunkName: "component---src-pages-akshata-manelkar-js" */),
  "component---src-pages-contect-js": () => import("./../../../src/pages/contect.js" /* webpackChunkName: "component---src-pages-contect-js" */),
  "component---src-pages-general-paediatric-js": () => import("./../../../src/pages/general-paediatric.js" /* webpackChunkName: "component---src-pages-general-paediatric-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juned-shaikh-js": () => import("./../../../src/pages/juned-shaikh.js" /* webpackChunkName: "component---src-pages-juned-shaikh-js" */),
  "component---src-pages-naushad-ali-js": () => import("./../../../src/pages/naushad-ali.js" /* webpackChunkName: "component---src-pages-naushad-ali-js" */),
  "component---src-pages-sathiya-karthigeyan-js": () => import("./../../../src/pages/sathiya-karthigeyan.js" /* webpackChunkName: "component---src-pages-sathiya-karthigeyan-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-shahzad-sarwar-js": () => import("./../../../src/pages/shahzad-sarwar.js" /* webpackChunkName: "component---src-pages-shahzad-sarwar-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-vijaykumar-packiaraj-js": () => import("./../../../src/pages/vijaykumar-packiaraj.js" /* webpackChunkName: "component---src-pages-vijaykumar-packiaraj-js" */)
}

